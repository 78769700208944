import Analytics from '../../SiteNav/analytics.js'
import { parseQuery } from '@cwp/utils'

import './navigation.less'

/*
 * Careers Site Footer
 */
class CareersFooter extends HTMLElement {
  connectedCallback () {
    this.shadow = this.attachShadow({ mode: 'open' })

    this.template = this.querySelector('#footer-template')

    this.template && this.template.content && this.shadow.appendChild(this.template.content)

    window.addEventListener('skipfocus', (event) => {
      const focusElement = this.shadow.querySelector(event.detail)
      focusElement && focusElement.focus()
    })

    // Footer Analytics Tagging
    const footerContent = this.shadow.querySelector('.careers-Footer')

    const footerLinks = footerContent && footerContent.getElementsByTagName('a')
    const menuItems = footerContent.querySelectorAll('.careers-FooterNavItemLevelOne')
    const oneTrustLabel = footerContent.getAttribute('data-one-trust')
    const oneTrustUrl = footerContent.getAttribute('data-one-trust-url')
    const legalMenuItems = [...menuItems].filter(elem => {
      if(elem.children[0].innerText === 'Legal'){
        return elem
      }
    })
    const json = document.createElement('script');
    json.setAttribute(
      'src',
      'https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location/geofeed'
    );
    document.head.appendChild(json);
  
    window.geofeed = (options) => {
      const country = options.country.toString();
      const state = options.state.toString();
    
      if (state === 'CA' && country === 'US' ) {
        const newMenuItem = document.createElement('li')
        newMenuItem.classList.add('careers-FooterNavItemLevelTwo')
        const newMenuLink = document.createElement('a')
        newMenuLink.setAttribute('href', oneTrustUrl)
        newMenuLink.textContent = oneTrustLabel
        newMenuItem.appendChild(newMenuLink)
        const listItems = [...legalMenuItems[0].children]
        listItems[1].appendChild(newMenuItem)
      }   
    }

    footerLinks && footerLinks.length > 0 && footerLinks.forEach((link) => {
      link && link.addEventListener('click', (event) => {
        const footerMessage = event.target.text
        const href = event.currentTarget && event.currentTarget.getAttribute('href')
        let siteId = ''
        if (href.indexOf('&siteid') > -1) {
          siteId = parseQuery(href).siteid.split('#')[0]
        }
        const type = 'footer'

        switch(footerMessage.toLowerCase()) {
          case "about ameriprise":
              try {
                const customAnalytics = new Analytics()
                customAnalytics.setSatelliteTrack('careersNavigationClicks', href)
              } catch (e) {
              }
          break
        }

        try {
          const footerAnalytics = new Analytics()

          footerAnalytics.ping(footerMessage, href, siteId, type)
        } catch (e) { }
      })
    })

    const footerLeftContent = document.querySelector('.leftColAnalytics')
    const leftContentLinks = footerLeftContent && footerLeftContent.getElementsByTagName('a')
    leftContentLinks && leftContentLinks.length > 0 && leftContentLinks.forEach((link) => {
      link && link.addEventListener('click', (event) => {
        const footerMessage = event.currentTarget && event.currentTarget.getAttribute('data-analytics')
        const href = event.currentTarget && event.currentTarget.getAttribute('href')
        let siteId = ''
        if (href.indexOf('&siteid') > -1) {
          siteId = parseQuery(href).siteid.split('#')[0]
        }
          
        let type = 'footer'
        switch(footerMessage.toLowerCase()) {
          case "search and apply":
          case "contact a recruiter":
              try {
                const customAnalytics = new Analytics()
                customAnalytics.setSatelliteTrack('careersNavigationClicks', href)
              } catch (e) {
              }
          break
        }

        try {
          const leftFooterAnalytics = new Analytics()
          leftFooterAnalytics.ping(footerMessage, href, siteId, type)
         } catch (e) {
         }

      })
    })


  }
}

window.customElements.define('careers-footer', CareersFooter)
